import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile--is-selected`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile__chevron`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile__checkmark`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile__checkmark:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64 / 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128 / 8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--tile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding (minimum)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "63.17934782608696%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Structure and spacing measurements for tile",
        "title": "Structure and spacing measurements for tile",
        "src": "/static/85e8488e28f76006ccce0eabf254c1d9/fb070/tile-style-2.png",
        "srcSet": ["/static/85e8488e28f76006ccce0eabf254c1d9/d6747/tile-style-2.png 288w", "/static/85e8488e28f76006ccce0eabf254c1d9/09548/tile-style-2.png 576w", "/static/85e8488e28f76006ccce0eabf254c1d9/fb070/tile-style-2.png 1152w", "/static/85e8488e28f76006ccce0eabf254c1d9/c3e47/tile-style-2.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h3 {...{
      "id": "proportions-for-grid"
    }}>{`Proportions for grid`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Percentage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`XL 1600-1200`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`L 1200-992`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`M 992-768`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`S 768-576`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`XS 576-0`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`100%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1/2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2/3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1/3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1/4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1/6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      